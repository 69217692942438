import {
	ChatBubbleBottomCenterTextIcon,
	DocumentTextIcon,
	InformationCircleIcon,
	PencilIcon,
	TrashIcon,
} from "@heroicons/react/20/solid";
import { UsersIcon } from "@heroicons/react/24/outline";
import Decimal from "decimal.js-light";
import CreateStaffingSidebar from "modules/project/components/ProjectDetailsView/components/CreateStaffingSidebar";
import DeleteOrderModal from "modules/project/components/ProjectDetailsView/components/DeleteOrderModal";
import DeleteStaffingModal from "modules/project/components/ProjectDetailsView/components/DeleteStaffingModal";
import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";

import ButtonNewItem from "~/components/buttons/ButtonNewItem";
import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import Card from "~/components/Card";
import SectionHeading from "~/components/headings/SectionHeading";
import SectionHeadingTabs from "~/components/headings/SectionHeadingTabbar";
import Headline from "~/components/Headline";
import HorizontalStats from "~/components/HorizontalStats";
import type { HorizontalStat } from "~/components/HorizontalStats/horizontalStatsTypes.ts";
import Status from "~/components/Status";
import { useClientsInvoiceRecipients } from "~/modules/billing/api/invoiceRecipient/invoiceRecipientQueries.ts";
import { useClientsContactPersons } from "~/modules/client/api/clientContactPerson/clientContactPersonQueries.ts";
import type { Order } from "~/modules/project/api/order/orderTypes.ts";
import type { ProjectRole } from "~/modules/project/api/projectRole/projectRoleTypes.ts";
import type { Staffing } from "~/modules/project/api/staffing/staffingTypes.ts";
import MonthlyTimeTrackingReport
	from "~/modules/project/components/ProjectDetailsView/components/OrderDetails/components/MonthlyTimeTrackingReport";
import StaffingDetails
	from "~/modules/project/components/ProjectDetailsView/components/OrderDetails/components/StaffingDetails.tsx";
import UpdateOrderSidebar from "~/modules/project/components/ProjectDetailsView/components/UpdateOrderSidebar";
import UpdateStaffingSidebar from "~/modules/project/components/ProjectDetailsView/components/UpdateStaffingSidebar";
import type { EnrichedStaffing } from "~/modules/project/types/projectViewTypes.ts";
import type { User } from "~/modules/user/api/user/userTypes.ts";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatDateRange } from "~/utils/dateAndTimeUtils.ts";
import getOneOfCollection from "~/utils/getOneOfCollection.ts";

interface OrderProps {
	allUsers: User[];
	clientId: string;
	orderData: Order;
	projectRoles: ProjectRole[];
	staffings: Staffing[];
	totalOrders: number;
	showBudget: boolean;
}

type VisibleSections = "staffings" | "details" | "comments" | "monthly-overview";

const OrderDetails: FunctionComponent<OrderProps> = ({
	allUsers,
	clientId,
	orderData,
	projectRoles,
	showBudget,
	staffings,
	totalOrders,
}) => {
	const [visibleSection, setVisibleSection] = useState<VisibleSections | null>("staffings");

	const {
		budgetCents,
		budgetCentsTracked,
		manDays: manDaysPlanned,
		minutesTracked,
		clientContactPersonId,
		comment,
		confirmedAt,
		endDate,
		invoiceByMail,
		invoiceRecipientId,
		isBilledMonthly,
		isFixedPrice,
		isForeignCountry,
		isSkillBased,
		orderNumber,
		paymentTargetDays,
		startDate,
		title,
		travelExpensesIncluded,
	} = orderData;
	const [showCreateStaffingSidebar, setShowCreateStaffingSidebar] = useState(false);
	const [showUpdateOrderSidebar, setShowUpdateOrderSidebar] = useState(false);
	const [showDeleteOrderSidebar, setShowDeleteOrderSidebar] = useState(false);
	const [selectedStaffingIdForUpdate, setSelectedStaffingIdForUpdate] = useState<string | null>(null);
	const [selectedStaffingIdForDeletion, setSelectedStaffingIdForDeletion] = useState<string | null>(null);
	const { data: contactPersonsData } = useClientsContactPersons(clientId);
	const { data: invoiceRecipientsData } = useClientsInvoiceRecipients(clientId);

	const invoiceRecipientName = useMemo(() => {
		if (invoiceRecipientId) {
			const invoiceRecipient = getOneOfCollection(invoiceRecipientsData, invoiceRecipientId);
			return invoiceRecipient?.displayName + " (Zentraler Rechnungsempfänger)";
		} else if (clientContactPersonId) {
			const contactPerson = getOneOfCollection(contactPersonsData, clientContactPersonId);
			return contactPerson?.fullName + " (Kontaktperson)";
		}

		return "n/a";
	}, [contactPersonsData, invoiceRecipientsData, invoiceRecipientId, clientContactPersonId]);

	const orderDetailsData = [
		{ label: "Personentage", content: manDaysPlanned },
		{ label: "Budget", content: formatCentsToCurrency(budgetCents) },
		{
			label: "Laufzeit",
			content: startDate && endDate ? `${formatDateRange(new Date(startDate), new Date(endDate))}` : "n/a",
		},
		{
			label: "Rechnungsempfänger",
			content: <>{invoiceRecipientName}</>,
		},
		{
			label: "Abrechnungsart",
			content: (
				<>
					{isFixedPrice ? "Festpreis" : "Tagessatz"}, {isBilledMonthly ? "monatlich" : "individuell"}
				</>
			),
		},
		{ label: "Reisekosten", content: travelExpensesIncluded ? "included" : "On top" },
		{ label: "Rechnung", content: invoiceByMail ? "Mail" : "Post" },
		{ label: "Abrechnung Rolle", content: isSkillBased ? "Projektrolle" : "Skillklasse" },
		{ label: "Zahlungsziel", content: paymentTargetDays + " Tage" },

		{ label: "Leistung/Rechnung Ausland", content: isForeignCountry ? "ja" : "nein" },
	];

	const popOverItems = useMemo(() => {
		const items = [
			{
				label: "Bestellung bearbeiten",
				onClick: () => setShowUpdateOrderSidebar(true),
				icon: PencilIcon,
			}];

		if (minutesTracked === 0 && totalOrders > 1) {
			items.push({
				label: "Bestellung löschen",
				onClick: () => setShowDeleteOrderSidebar(true),
				icon: TrashIcon,
			});
		}

		return items;

	}, [minutesTracked, totalOrders]);

	const [enrichedStaffings, manDaysAssigned, budgetCentsAssigned] = useMemo(() => {
		if (staffings && allUsers && projectRoles) {
			let manDaysAssigned = 0;
			let budgetCentsAssigned = 0;
			const enrichedStaffings: EnrichedStaffing[] = [];
			const projectLeadRoleId = projectRoles.find((pr) => pr.displayName === "Projektleiter")?.id;
			staffings.forEach((staffing) => {
				const user = allUsers.find((u) => staffing.userId === u.id)!;

				enrichedStaffings.push({
					avatarImage: user.avatarImage,
					fullName: user.fullName,
					firstName: user.firstName,
					lastName: user.lastName,
					userIsActive: user.isActive,
					...staffing,
				});

				manDaysAssigned += staffing.manDays;
				budgetCentsAssigned += new Decimal(staffing.manDays).mul(staffing.dailyRateCents).toNumber();
			});

			const enrichedStaffingsSorted = enrichedStaffings.sort((a, b) => {
				// Sort by isActive
				if (a.isActive && !b.isActive) {
					return -1;
				}
				if (!a.isActive && b.isActive) {
					return 1;
				}

				// Sort by projectLeadRoleId within the active and inactive blocks
				if (a.projectRoleId === projectLeadRoleId && b.projectRoleId !== projectLeadRoleId) {
					return -1;
				}
				if (a.projectRoleId !== projectLeadRoleId && b.projectRoleId === projectLeadRoleId) {
					return 1;
				}

				// Sort by fullName within those blocks
				return a.fullName.localeCompare(b.fullName);
			});
			return [enrichedStaffingsSorted, manDaysAssigned, budgetCentsAssigned];
		}
		return [[], 0, 0];
	}, [staffings, allUsers, projectRoles]);

	const [orderBudgetStats, orderManDaysStats] = useMemo(() => {
		const orderBudgetStats: HorizontalStat[] = [
			{
				label: "Bestellvolumen",
				value: formatCentsToCurrency(budgetCents),
			},
			{
				label: "Im Staffing zugewiesen",
				value: formatCentsToCurrency(budgetCentsAssigned),
				isHighlighted: budgetCentsAssigned > budgetCents,
			},
			{
				label: "Verfügt",
				value: formatCentsToCurrency(budgetCentsTracked),
			},
			{
				label: "Rest",
				value: formatCentsToCurrency(budgetCents - budgetCentsTracked),
				isHighlighted: budgetCentsTracked > budgetCents,
			},
		];

		const orderManDaysStats: HorizontalStat[] = [
			{
				label: "Bestellvolumen",
				value: parseFloat(manDaysPlanned).toFixed(0) + " PT",
			},
			{
				label: "Im Staffing zugewiesen",
				value: manDaysAssigned.toFixed(0) + " PT",
				isHighlighted: manDaysAssigned > parseFloat(manDaysPlanned),
			},
			{
				label: "Verfügt",
				value: Math.round(minutesTracked / 60 / 8).toFixed(0) + " PT",
			},
			{
				label: "Rest",
				value: (parseFloat(manDaysPlanned) - Math.round(minutesTracked / 60 / 8)).toFixed(0) + " PT",
				isHighlighted: budgetCentsTracked > budgetCents,
			},
		];

		return [orderBudgetStats, orderManDaysStats];
	}, [budgetCents, budgetCentsAssigned, budgetCentsTracked, manDaysAssigned, manDaysPlanned, minutesTracked]);


	const totalManDaysUnassigned = parseFloat(manDaysPlanned) - manDaysAssigned;
	const totalBudgetUnassigned = budgetCents - budgetCentsAssigned;

	const handleTabClick = (tabIndex: string) => {
		if (tabIndex === visibleSection) {
			setVisibleSection(null);
		} else {
			setVisibleSection(tabIndex as VisibleSections);
		}
	};

	return (
		<>
			<section className="flex flex-col">
				<div className="rounded-t-lg bg-gray-200 p-4 text-gray-500 transition-colors duration-200">
					<div className="flex justify-between gap-8">
						<Headline type="h4"
								  className="mb-0">
							{title}
						</Headline>
						<div className="flex items-center justify-end gap-2">
							<Status theme={null === confirmedAt ? "error" : "success"}>
								{null === confirmedAt ? "Nicht bestätigt" : "Bestätigt"}
							</Status>
							<Status>
								Bestellnummer: {orderNumber || <span className="ml-1 opacity-40">nicht vorhanden</span>}
							</Status>
							<ButtonWithPopover
								theme="dark"
								items={popOverItems}
							/>
						</div>
					</div>
				</div>
				<div className="relative rounded-b-lg bg-gray-400/5">
					<div className="mx-4 mt-4 flex gap-x-4">
						<div className="min-w-0 grow">
							<Card>
								<HorizontalStats stats={orderBudgetStats} />
							</Card>
						</div>
						<div className="min-w-0 grow">
							<Card>
								<HorizontalStats stats={orderManDaysStats} />
							</Card>
						</div>
					</div>
					<div className="px-4">
						<SectionHeading theme={undefined === visibleSection ? "none" : "dark"}>
							<SectionHeadingTabs
								tabs={[
									{
										name: `Staffing (${staffings.length})`,
										value: "staffings",
										icon: <UsersIcon className="w-5" />,
										active: visibleSection === "staffings",
									},
									{
										name: "Erfassungen",
										value: "monthly-overview",
										icon: <DocumentTextIcon className="w-5" />,
										active: visibleSection === "monthly-overview",
									},
									{
										name: "Bestelldetails",
										value: "details",
										icon: <InformationCircleIcon className="w-5" />,
										active: visibleSection === "details",
									},
									{
										name: `Kommentare (${!comment ? 0 : 1})`,
										value: "comments",
										icon: <ChatBubbleBottomCenterTextIcon className="w-5" />,
										active: visibleSection === "comments",
									},
								]}
								onTabClick={handleTabClick}
							/>
						</SectionHeading>
					</div>
					<div>
						{"staffings" === visibleSection && (
							<dl className="m-4 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
								{enrichedStaffings && enrichedStaffings
									.map(
										({
											avatarImage,
											budgetCentsTracked,
											dailyRateCents,
											id,
											isActive,
											manDays,
											minutesTracked,
											projectRoleId,
											firstName,
											lastName,
											fullName,
											projectId,
											userId,
											userIsActive,
										}) => {
											const firstNameSanitized = firstName ? firstName : "--";
											const lastNameSanitized = lastName ? lastName : "--";
											const fullNameSanitized = fullName ? fullName : "--";

											const projectRole = projectRoles.find((pr) => projectRoleId === pr.id);

											const manyDaysTracked = Math.ceil(minutesTracked / 60 / 8 * 100) / 100;

											return (
												<StaffingDetails
													key={id}
													avatarImage={avatarImage}
													budgetCentsPlanned={manDays * dailyRateCents}
													budgetCentsTracked={budgetCentsTracked}
													dailyRateCents={dailyRateCents}
													firstName={firstNameSanitized}
													fullName={fullNameSanitized}
													lastName={lastNameSanitized}
													manDaysPlanned={manDays}
													manDaysTracked={manyDaysTracked}
													onEditClick={() => setSelectedStaffingIdForUpdate(id)}
													onEditRemove={() => setSelectedStaffingIdForDeletion(id)}
													projectId={projectId}
													projectRole={projectRole}
													showBudget={showBudget}
													staffedUserId={userId}
													staffingId={id}
													staffingIsActive={isActive}
													userIsActive={userIsActive}
												/>
											);
										},
									)}
								<ButtonNewItem size="sm"
											   theme="inline"
											   onClick={() => setShowCreateStaffingSidebar(true)}>
									Staffing hinzufügen
								</ButtonNewItem>
							</dl>
						)}

						{"details" === visibleSection && (
							<dl className="m-4 divide-y divide-gray-100">
								{orderDetailsData.map(({ label, content }, dIndex) => (
									<div key={dIndex}
										 className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
										<dt className="text-sm font-medium leading-6 text-gray-900">{label}</dt>
										<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
											{content}
										</dd>
									</div>
								))}
							</dl>
						)}

						{"comments" === visibleSection && (
							<>
								{comment ? (
									<div className="m-4 text-sm">{comment}</div>
								) : (
									<div className="m-4 text-xs text-gray-500">Keine Kommentare vorhanden.</div>
								)}
							</>
						)}

						{"monthly-overview" === visibleSection && <MonthlyTimeTrackingReport
							orderId={orderData.id}
							ordersStaffings={staffings}
							projectId={orderData.projectId} />}
					</div>
				</div>
			</section>
			<UpdateOrderSidebar
				isOpen={showUpdateOrderSidebar}
				setOpen={setShowUpdateOrderSidebar}
				clientId={clientId}
				projectId={orderData.projectId}
				orderData={orderData}
			/>

			<CreateStaffingSidebar
				isOpen={showCreateStaffingSidebar}
				onClose={() => setShowCreateStaffingSidebar(false)}
				orderId={orderData.id}
				projectId={orderData.projectId}
				projectPhaseId={orderData.projectPhaseId}
				totalBudgetUnassigned={totalBudgetUnassigned}
				totalManDaysUnassigned={totalManDaysUnassigned}
			/>

			{selectedStaffingIdForUpdate && (
				<UpdateStaffingSidebar
					isOpen={!!selectedStaffingIdForUpdate}
					close={() => setSelectedStaffingIdForUpdate(null)}
					projectId={orderData.projectId}
					staffingId={selectedStaffingIdForUpdate}
					totalBudgetUnassigned={totalBudgetUnassigned}
					totalManDaysUnassigned={totalManDaysUnassigned}
				/>
			)}
			<DeleteStaffingModal
				isOpen={!!selectedStaffingIdForDeletion}
				staffingId={selectedStaffingIdForDeletion}
				projectId={orderData.projectId}
				onCloseClick={() => setSelectedStaffingIdForDeletion(null)}
			/>
			<DeleteOrderModal
				isOpen={showDeleteOrderSidebar}
				orderId={orderData.id}
				orderTitle={orderData.title}
				projectId={orderData.projectId}
				onCloseClick={() => setShowDeleteOrderSidebar(false)}
			/>
		</>
	);
};

export default OrderDetails;
