import type { FunctionComponent, PropsWithChildren } from "react";
import { Fragment } from "react";

import HorizontalStatDetail from "~/components/HorizontalStats/components/HorizontalStatDetail";
import HorizontalStatDivider
	from "~/components/HorizontalStats/components/HorizontalStatDetail/HorizontalStatDivider/HorizontalStatDivider";
import type { HorizontalStat } from "~/components/HorizontalStats/horizontalStatsTypes.ts";

type Props = PropsWithChildren & {
	colorClassName?: string;
	highlightColorClassName?: string;
	stats: HorizontalStat[];
};

const HorizontalStats: FunctionComponent<Props> = ({
	children,
	colorClassName,
	highlightColorClassName,
	stats,
}) => {
	return <div className="flex h-full justify-between">
		{children}
		{stats.map((stat, index) => {
			return <Fragment key={index}>
				<HorizontalStatDetail key={index}
									  label={stat.label}
									  value={stat.value}
									  colorClassName={colorClassName}
									  highlightColorClassName={highlightColorClassName}
									  isHighlighted={stat.isHighlighted} />
				{index < stats.length - 1 && <HorizontalStatDivider />}
			</Fragment>;
		})}
	</div>;
};

export default HorizontalStats;