import type { SortingState } from "@tanstack/react-table";
import type Decimal from "decimal.js-light";

import type { AvatarImage } from "~/modules/image/api/avatarImage/avatarImageTypes.ts";
import type { TimeTracking } from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";
import type { ColumnFilterType } from "~/types/tanstackTableTypes.ts";

export type ProjectsTimeTrackingsTableData = {
	dailyRateCents: number;
	date: Date,
	deliverablesText: string;
	location: string;
	minutesTracked: number;
	orderId: string;
	orderTitle: string;
	phaseId: string;
	phaseTitle: string;
	projectRoleDisplayName: string;
	totalAmountCents: number;
	userFullName: string;
	userId: string;
};

export enum ProjectDetailsTabName {
	Overview = "overview",
	TimeTrackings = "time-trackings",
	Phase = "phase-",
}

export type ProjectDetailsTimeTrackingsPageState = {
	columnFilters: ColumnFilterType[],
	sorting: SortingState,
};

export type TimeTrackingExcelData = {
	userFullName: string;
	projectRoleDisplayName: string;
	phaseTitle: string;
	orderTitle: string;
	date: Date;
	dailyRateCents: number;
	minutesTracked: number;
	totalAmountCents: number;
};


export type MonthlyReportData = {
	userDisplayName: string;
	dailyRateCents: number;
	totalDays: Decimal;
	totalHours: Decimal;
	totalCents: Decimal;
	timeTrackings: TimeTrackingListEntry[];
}

export type TimeTrackingListEntry = {
	date: Date,
	hours: Decimal,
	locationDisplayName: string,
	text: string
}

export type EnrichedStaffing = {
	id: string;
	createdAt: string;
	budgetCentsTracked: number;
	dailyRateCents: number;
	isActive: boolean;
	manDays: number;
	minutesTracked: number;
	orderId: string;
	projectId: string;
	projectPhaseId: string;
	projectRoleId: string;
	timeTrackings?: TimeTracking[] | undefined;
	userId: string;
	updatedAt: string;
	avatarImage: AvatarImage | null;
	fullName: string;
	firstName: string;
	lastName: string;
	userIsActive: boolean;
}