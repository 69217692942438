import type { FunctionComponent } from "react";
import { useParams } from "react-router-dom";

import { useAggregatedDataV2 } from "~/hooks/useAggregatedDataV2.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import AdminView from "~/modules/admin/components/AdminView";
import { AdminViewTabName } from "~/modules/admin/types/adminViewTypes.ts";
import { useAllClients } from "~/modules/client/api/client/clientQueries.ts";
import {
	useAllDeliverableTags,
	useDeliverableTagUsageReport,
} from "~/modules/project/api/deliverableTags/deliverableTagsQueries.ts";
import { useAllProductTags, useProductTagUsageReport } from "~/modules/project/api/productTags/productTagsQueries.ts";
import { useAllProjects } from "~/modules/project/api/project/projectQueries.ts";
import { PermissionNames } from "~/types/entityNames.ts";

const AdminPage: FunctionComponent = () => {
	const guard = userViewGuard([PermissionNames.CanManageProjectTags, PermissionNames.CanManageUsers]);
	if (guard) return guard;

	const { tabId } = useParams();

	const sanitizedTabId = tabId as AdminViewTabName || AdminViewTabName.Users;
	const deliverableTagsLoadingEnabled = sanitizedTabId === AdminViewTabName.DeliverableTags;
	const productTagsLoadingEnabled = sanitizedTabId === AdminViewTabName.ProductTags;


	const { options: { loadingPercent: productTagsLoadingPercent } } = useAggregatedDataV2({
		allClients: useAllClients(productTagsLoadingEnabled),
		allProjectsIsLoading: useAllProjects(productTagsLoadingEnabled),
		allProductTags: useAllProductTags(productTagsLoadingEnabled),
		productTagUsageReport: useProductTagUsageReport(productTagsLoadingEnabled),
	});

	const { options: { loadingPercent: deliverableTagsLoadingPercent } } = useAggregatedDataV2({
		allClients: useAllClients(deliverableTagsLoadingEnabled),
		allProjectsIsLoading: useAllProjects(deliverableTagsLoadingEnabled),
		allDeliverableTags: useAllDeliverableTags(deliverableTagsLoadingEnabled),
		deliverableTagUsageReport: useDeliverableTagUsageReport(deliverableTagsLoadingEnabled),
	});

	return <AdminView activeTabId={sanitizedTabId}
					  deliverableTagsLoadingPercent={deliverableTagsLoadingPercent}
					  productTagsLoadingPercent={productTagsLoadingPercent}
	/>;
};

export default AdminPage;