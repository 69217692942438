import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getProductTagsUsageReport,
	indexProductTags,
} from "~/modules/project/api/productTags/productTagsApiDispatchers.ts";

export const PRODUCT_TAG_BASE_QUERY_KEY = "product-tags";

export const ALL_PRODUCT_TAGS_QUERY_KEY = [PRODUCT_TAG_BASE_QUERY_KEY, "all"];

export const useAllProductTags = (enabled: boolean = true) => useQuery({
	queryKey: ALL_PRODUCT_TAGS_QUERY_KEY,
	queryFn: () => indexProductTags().then(getDataFromResponse),
	enabled,
});

export const PRODUCT_TAG_USAGE_REPORT_QUERY_KEY = [PRODUCT_TAG_BASE_QUERY_KEY, "usage-report"];

export const useProductTagUsageReport = (enabled: boolean = true) => useQuery({
	queryKey: PRODUCT_TAG_USAGE_REPORT_QUERY_KEY,
	queryFn: () => getProductTagsUsageReport().then(getDataFromResponse),
	enabled,
});