import TimeTrackingView from "modules/timeTracking/components/TimeTrackingView";
import { useEffect, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { useAuth } from "~/contexts/AuthContext";
import useMonthSelectOptions from "~/hooks/form/formOptionsData/useMonthSelectOptions.ts";
import useUserSelectOptions, { staffableUsersFilterFn } from "~/hooks/form/formOptionsData/useUsersSelectOptions.ts";
import useYearSelectOptions from "~/hooks/form/formOptionsData/useYearSelectOptions.ts";
import { useAggregatedDataV2 } from "~/hooks/useAggregatedDataV2.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAllAbsenceTypes } from "~/modules/absence/api/absenceType/absenceTypeQueries.ts";
import { useHolidaysByYear } from "~/modules/absence/api/holiday/holidayQueries.ts";
import { useAllCompanyAvatarImages } from "~/modules/image/api/avatarImage/avatarImageQueries.ts";
import { useAllLocations, useUsersAvailableLocations } from "~/modules/location/api/location/locationQueries.ts";
import { useUsersActiveStaffings } from "~/modules/project/api/staffing/staffingQueries.ts";
import { useUsersAvailableDeliverables } from "~/modules/timeTracking/api/deliverable/deliverableQueries.ts";
import { useUsersMonthlyClosings } from "~/modules/timeTracking/api/monthlyClosing/monthlyClosingQueries.ts";
import { useAllTimeTrackingTypes } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeQueries.ts";
import { useAllUsers } from "~/modules/user/api/user/userQueries";
import LoadingPage from "~/pages/LoadingPage";
import { PermissionNames } from "~/types/entityNames.ts";


const currentMonth = new Date().getMonth().toString();
const currentYear = new Date().getFullYear().toString();

const TimeTrackingPage: React.FunctionComponent = () => {
	const guard = userViewGuard(null, PermissionNames.CanManageTimeTrackings);

	if (guard) return guard;

	const { hasAnyPermission } = useAuth();
	const { userId } = useParams();

	const currentUserId = userId!;
	const [searchParams, setSearchParams] = useSearchParams();

	const monthSelectOptions = useMonthSelectOptions();
	const yearSelectOptions = useYearSelectOptions(2018, new Date().getFullYear());

	const [year, month] = useMemo(() => {
		const year = searchParams.get("year");
		const month = searchParams.get("month");

		const isValidYear = yearSelectOptions.find((option) => option.value === year);
		const isValidMonth = monthSelectOptions.find((option) => option.value === (parseInt(month || "") - 1).toString());
		if (!year || !month || !isValidYear || !isValidMonth) {
			return [];
		}

		return [year, (parseInt(month) - 1).toString()];
	}, [monthSelectOptions, searchParams, yearSelectOptions]);

	useEffect(() => {
		if (!year || !month) {
			setSearchParams({ year: currentYear, month: (parseInt(currentMonth) + 1).toString() });
		}
	}, [year, month, setSearchParams]);


	const { data, options } = useAggregatedDataV2({
		activeStaffings: useUsersActiveStaffings(currentUserId),
		allAbsenceTypes: useAllAbsenceTypes(),
		allCompanyAvatarImages: useAllCompanyAvatarImages(),
		availableDeliverables: useUsersAvailableDeliverables(currentUserId),
		availableLocations: useUsersAvailableLocations(currentUserId),
		holidays: useHolidaysByYear(year!),
		locations: useAllLocations(),
		timeTrackingTypes: useAllTimeTrackingTypes(),
		users: useAllUsers(),
		usersMonthlyClosingsData: useUsersMonthlyClosings(currentUserId),
	});

	const userSelectOptions = useUserSelectOptions({ filterFunction: staffableUsersFilterFn });

	const {
		activeStaffings,
		allAbsenceTypes,
		availableDeliverables,
		availableLocations,
		holidays,
		locations,
		timeTrackingTypes,
		users,
		usersMonthlyClosingsData,
	} = useMemo(() => data, [data]);

	if (!year || !month) return null;

	if (options.loadingPercent < 100) return <LoadingPage pcent={options.loadingPercent} />;

	return (
		<TimeTrackingView
			activeStaffings={activeStaffings}
			allAbsenceTypes={allAbsenceTypes}
			availableDeliverables={availableDeliverables}
			availableLocations={availableLocations}
			currentMonth={currentMonth}
			currentYear={year}
			holidays={holidays}
			locations={locations}
			month={month}
			monthSelectOptions={monthSelectOptions}
			timeTrackingTypes={timeTrackingTypes}
			userCanManageTimeTracking={hasAnyPermission(PermissionNames.CanManageTimeTrackings)}
			userId={currentUserId}
			users={users}
			userSelectOptions={userSelectOptions}
			usersMonthlyClosingsData={usersMonthlyClosingsData}
			year={year}
			yearSelectOptions={yearSelectOptions}
		/>
	);
};

export default TimeTrackingPage;
