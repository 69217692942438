import { useQueryClient } from "@tanstack/react-query";
import type { FunctionComponent } from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import CreateDeliverableTagForm
	from "~/modules/admin/components/AdminView/components/AdminDeliverableTagsSection/components/CreateDeliverableTagSidebar/components/CreateDeliverableTagForm";
import { ALL_DELIVERABLE_TAGS_QUERY_KEY } from "~/modules/project/api/deliverableTags/deliverableTagsQueries.ts";

type CreateDeliverableTagSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
};

const CreateDeliverableTagSidebar: FunctionComponent<CreateDeliverableTagSidebarProps> = ({
	isOpen,
	onClose,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries({ queryKey: ALL_DELIVERABLE_TAGS_QUERY_KEY });
		onClose();
	}, [onClose, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			{isOpen && <CreateDeliverableTagForm onSuccess={handleSuccess}
												 onCancel={onClose} />}
		</Sidebar>
	);
};

export default CreateDeliverableTagSidebar;
