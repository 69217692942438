import { useQueryClient } from "@tanstack/react-query";
import type { ReactElement } from "react";
import { createContext, useCallback, useContext, useEffect, useState } from "react";

import { getDataFromResponse } from "~/api/axiosUtils";
import { getAccessTokenForUser, login, logout } from "~/modules/auth/api/auth/authApiDispatchers";
import { GET_AUTHENTICATED_USER_QUERY_KEY, useAuthenticatedUserQuery } from "~/modules/auth/api/auth/authQueries";
import type { AuthenticatedUser } from "~/modules/user/api/user/userTypes.ts";
import { deleteIntendedLocation } from "~/services/intendedLocation";
import { ACCESS_TOKEN_KEY, deleteTokens, getTokens, REFRESH_TOKEN_KEY, setTokens } from "~/services/tokenService";
import type { PermissionNames } from "~/types/entityNames.ts";

type AuthContextProviderProps = {
	accessToken: string | null;
	children: React.ReactNode;
};

type AdminTokens = {
	accessToken: string;
	refreshToken: string;
};

export type HasAnyPermissionParamsType = PermissionNames[] | PermissionNames | null;

interface AuthContextInterface {
	user: AuthenticatedUser | undefined;
	isCheckingAuthStatus: boolean;
	isImpersonating: boolean;

	signIn(username: string, password: string): void;

	signOut(): void;

	impersonateUser(userId: string): void;

	stopImpersonating(): void;

	hasAnyPermission(permissionsToCheck: HasAnyPermissionParamsType): boolean;

	hasAnyRole(rolesToCheck: [string | null] | string | null): boolean;
}

const AuthContext = createContext<AuthContextInterface | null>(null);

export const AuthContextProvider = ({ accessToken, children }: AuthContextProviderProps): ReactElement => {
	const [authToken, setAuthToken] = useState(accessToken);
	const [adminTokens, setAdminTokens] = useState<AdminTokens | null>(null);
	const [isImpersonating, setIsImpersonating] = useState(false);
	const [isCheckingAuthStatus, setIsCheckingAuthStatus] = useState(true);
	const queryClient = useQueryClient();

	const { data: user, isError, refetch } = useAuthenticatedUserQuery(!!authToken);

	useEffect(() => {
		if (user || !authToken) {
			setIsCheckingAuthStatus(false);
		}

		if (user) {
			deleteIntendedLocation();
		}
	}, [user, authToken]);

	useEffect(() => {
		if (isError) {
			queryClient.setQueryData(GET_AUTHENTICATED_USER_QUERY_KEY, null);
			setAuthToken(null);
			deleteTokens();
		}
	}, [isError, queryClient]);

	const signIn = useCallback(async (email: string, password: string) => {
		try {
			const loginResponse = await login({ email, password });
			const responseData = getDataFromResponse(loginResponse);
			const accessToken = responseData.accessToken;
			const refreshToken = responseData.refreshToken;

			setTokens({
				[ACCESS_TOKEN_KEY]: accessToken,
				[REFRESH_TOKEN_KEY]: refreshToken,
			});
			setAuthToken(accessToken);
			await refetch();
			setIsCheckingAuthStatus(false);
		} catch (error) {
			console.log({ error });
			throw new Error("Login failed");
		}
	}, [refetch]);

	const signOut = useCallback(async () => {
		try {
			await logout();
		} catch (error) {
			console.log(error);
		}
		queryClient.clear();
		setAuthToken(null);
		deleteTokens();
	}, [queryClient]);

	const impersonateUser = useCallback(async (userId: string) => {
		try {
			const impersonateResponse = await getAccessTokenForUser(userId);
			const accessToken = getDataFromResponse(impersonateResponse).accessToken;
			setAdminTokens(getTokens());
			setTokens({ [ACCESS_TOKEN_KEY]: accessToken, [REFRESH_TOKEN_KEY]: "" });
			setAuthToken(accessToken);
			setIsImpersonating(true);
			queryClient.invalidateQueries({ queryKey: GET_AUTHENTICATED_USER_QUERY_KEY });
		} catch (error) {
			console.log(error);
		}

	}, [setAdminTokens, queryClient]);

	const stopImpersonating = useCallback(async () => {
		try {
			const { accessToken, refreshToken } = adminTokens!;
			setTokens({ [ACCESS_TOKEN_KEY]: accessToken, [REFRESH_TOKEN_KEY]: refreshToken });
			setAdminTokens(null);
			setAuthToken(accessToken);
			setIsImpersonating(false);
			queryClient.invalidateQueries({ queryKey: GET_AUTHENTICATED_USER_QUERY_KEY });
		} catch (error) {
			console.log(error);
		}
	}, [queryClient, adminTokens]);

	const hasAnyPermission = useCallback(
		(permissionsToCheck: HasAnyPermissionParamsType) => {
			if (!user) return false;

			if (!permissionsToCheck) return true;

			if (typeof permissionsToCheck === "string") {
				permissionsToCheck = [permissionsToCheck];
			}

			return permissionsToCheck.filter((elem) => user.permissions.includes(elem || "")).length > 0;
		},
		[user],
	);

	const hasAnyRole = useCallback(
		(rolesToCheck: [string] | string) => {
			if (!user) return false;

			if (!rolesToCheck) return true;

			if (typeof rolesToCheck === "string") {
				rolesToCheck = [rolesToCheck];
			}

			return rolesToCheck.filter((elem) => user.roles.includes(elem)).length > 0;
		},
		[user],
	);

	return (
		<AuthContext.Provider
			value={{
				user,
				isCheckingAuthStatus,
				signIn,
				signOut,
				impersonateUser,
				isImpersonating,
				stopImpersonating,
				hasAnyPermission,
				hasAnyRole,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	const context = useContext(AuthContext);

	if (!context) {
		throw new Error("useAuth must be used within AuthContext");
	}

	return context;
};
