import { useQueryClient } from "@tanstack/react-query";
import type { FunctionComponent } from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import UpdateProductTagForm
	from "~/modules/admin/components/AdminView/components/AdminProductTagsSection/components/UpdateProductTagSidebar/components/UpdateProductTagForm";
import { ALL_PRODUCT_TAGS_QUERY_KEY } from "~/modules/project/api/productTags/productTagsQueries.ts";
import type { ProductTag } from "~/modules/project/api/productTags/productTagsTypes.ts";

type CreateProductTagSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	productTagData: ProductTag | null
};

const UpdateProductTagSidebar: FunctionComponent<CreateProductTagSidebarProps> = ({
	isOpen,
	onClose,
	productTagData,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries({ queryKey: ALL_PRODUCT_TAGS_QUERY_KEY });
		onClose();
	}, [onClose, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			{isOpen && productTagData && <UpdateProductTagForm onSuccess={handleSuccess}
															   onCancel={onClose}
															   productTagData={productTagData}
			/>}
		</Sidebar>
	);
};

export default UpdateProductTagSidebar;
