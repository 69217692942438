import { useQueryClient } from "@tanstack/react-query";
import type { FunctionComponent } from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import {
	ALL_DELIVERABLE_TAGS_QUERY_KEY,
	DELIVERABLE_TAG_USAGE_REPORT_QUERY_KEY,
} from "~/modules/project/api/deliverableTags/deliverableTagsQueries.ts";
import { ALL_PROJECTS_QUERY_KEY, PROJECT_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import UpdateDeliverableTagsForm
	from "~/modules/project/components/ProjectDetailsView/components/ProjectOverviewSection/components/ProjectDeliverableTagsSection/components/UpdateDeliverableTagsSidebar/components/UpdateDeliverableTagsForm";

type UpdateProductTagsSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	deliverableTagIds: string[];
	projectId: string;
};

const UpdateDeliverableTagsSidebar: FunctionComponent<UpdateProductTagsSidebarProps> = ({
	isOpen,
	onClose,
	deliverableTagIds,
	projectId,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async (newTagsAdded: boolean) => {
		await queryClient.refetchQueries({ queryKey: PROJECT_QUERY_KEY(projectId) });
		queryClient.invalidateQueries({ queryKey: DELIVERABLE_TAG_USAGE_REPORT_QUERY_KEY });
		if (newTagsAdded) {
			await queryClient.refetchQueries({ queryKey: ALL_DELIVERABLE_TAGS_QUERY_KEY });
		}
		queryClient.invalidateQueries({ queryKey: ALL_PROJECTS_QUERY_KEY });
		onClose();
	}, [onClose, projectId, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			{isOpen && <UpdateDeliverableTagsForm onSuccess={handleSuccess}
												  onClose={onClose}
												  projectId={projectId}
												  deliverableTagIds={deliverableTagIds} />}
		</Sidebar>
	);
};

export default UpdateDeliverableTagsSidebar;
