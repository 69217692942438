import { useQueryClient } from "@tanstack/react-query";
import type { FunctionComponent } from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import { PROJECT_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import UpdateProjectDescriptionForm
	from "~/modules/project/components/ProjectDetailsView/components/ProjectOverviewSection/components/ProjectDescriptionSection/components/UpdateProjectDescriptionSidebar/components/UpdateProjectDescriptionForm";

type Props = {
	descriptionText: string | null;
	isOpen: boolean;
	onClose: () => void;
	projectId: string;

};

const UpdateProjectDescriptionSidebar: FunctionComponent<Props> = ({
	descriptionText,
	isOpen,
	onClose,
	projectId,
}) => {

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries({ queryKey: PROJECT_QUERY_KEY(projectId) });
		onClose();
	}, [queryClient, projectId, onClose]);

	return (
		<Sidebar closeOnOutsideClick={false}

				 open={isOpen}
				 setOpen={() => onClose()}>
			{isOpen && <UpdateProjectDescriptionForm
				onSuccess={handleSuccess}
				onCancel={onClose}
				descriptionText={descriptionText}
				projectId={projectId}
			/>}
		</Sidebar>
	);
};

export default UpdateProjectDescriptionSidebar;
