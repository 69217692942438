import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import TimelineItem from "modules/humanResources/components/StaffMemberDetailsView/components/TimelineItem";
import type { FunctionComponent, ReactNode } from "react";
import { useMemo, useState } from "react";

import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import Card from "~/components/Card";
import LoadingSpinnerFull from "~/components/LoadingSpinners/LoadingSpinnerFull";
import { useAuth } from "~/contexts/AuthContext";
import { useStaffMembersWorkingSchedules } from "~/modules/absence/api/workingSchedule/workingScheduleQueries.ts";
import type { WorkingScheduleWithDate } from "~/modules/absence/api/workingSchedule/workingScheduleTypes.ts";
import { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import {
	useStaffMembersEmployments,
} from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsQueries.ts";
import type {
	StaffMemberEmploymentWithDate,
} from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsTypes.ts";
import StaffMemberTimeline
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberTimeline";
import CreateWorkingScheduleSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberWorkingSchedulesSection/components/WorkingSchedulesSection/components/CreateWorkingScheduleSidebar";
import DeleteWorkingScheduleModal
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberWorkingSchedulesSection/components/WorkingSchedulesSection/components/DeleteWorkingScheduleModal";
import UpdateWorkingScheduleSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberWorkingSchedulesSection/components/WorkingSchedulesSection/components/UpdateWorkingScheduleSidebar";
import WorkingScheduleWeek
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberWorkingSchedulesSection/components/WorkingSchedulesSection/components/WorkingScheduleWeek";
import TimelineEmptyMessage
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/TimelineEmptyMessage";
import { getEmploymentTypeDisplayNameById } from "~/modules/humanResources/utils/employmentTypeUtils.ts";
import { PermissionNames } from "~/types/entityNames.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type WorkingSchedulesViewProps = {
	minDateNewEntry: Date;
	staffMemberCoreData: StaffMember;
};

type TimelineElement = WorkingScheduleWithDate | StaffMemberEmploymentWithDate

const WorkingSchedulesSection: FunctionComponent<WorkingSchedulesViewProps> = ({
	minDateNewEntry,
	staffMemberCoreData,
}) => {
	const { hasAnyPermission } = useAuth();

	const [showCreateEntrySidebar, setShowCreateEntrySidebar] = useState(false);
	const [entryDataToUpdate, setEntryDataToUpdate] = useState<WorkingScheduleWithDate | null>(null);
	const [entryDataToDelete, setEntryDataToDelete] = useState<WorkingScheduleWithDate | null>(null);

	const userCanAdminVacations = hasAnyPermission(PermissionNames.CanManageAbsences);

	const {
		data: workingSchedulesData,
		isLoading: workingSchedulesIsLoading,
	} = useStaffMembersWorkingSchedules(staffMemberCoreData.userId || "");

	const {
		data: employmentsData,
		isLoading: employmentsIsLoading,
	} = useStaffMembersEmployments(staffMemberCoreData.id);

	const timeLineElements = useMemo(() => {
		if (employmentsData !== undefined && workingSchedulesData !== undefined) {
			if (workingSchedulesData.length === 0) {
				return [];
			}
			const leaveEvents = employmentsData
				.filter(employment => employment.employmentTypeId === EmploymentTypeId.Left)
				.map(employment => ({ ...employment, validFrom: new Date(employment.validFrom) }));

			const workingSchedulesWithDate = workingSchedulesData.map(workingSchedule => {
				return { ...workingSchedule, validFrom: new Date(workingSchedule.validFrom) };
			});

			const timelineElements: ReactNode[] = [];

			const workingSchedulesSorted: TimelineElement[] = [...workingSchedulesWithDate, ...leaveEvents].sort(byObjectProperty("validFrom", "desc"));

			workingSchedulesSorted.forEach((workingSchedule, index) => {
				const nextElement = workingSchedulesSorted[index + 1] as StaffMemberEmploymentWithDate;
				const nextElementIsLeftEntry = nextElement?.employmentTypeId !== undefined;

				const isStarItem = !nextElement || nextElementIsLeftEntry;
				const isLeftEntry = (workingSchedule as StaffMemberEmploymentWithDate).employmentTypeId !== undefined;
				const title = isLeftEntry ? getEmploymentTypeDisplayNameById(EmploymentTypeId.Left) :
					<WorkingScheduleWeek workingScheduleData={workingSchedule as WorkingScheduleWithDate} />;

				let dotTheme: "success" | "danger" | "neutral" = "neutral";
				if (isLeftEntry) {
					dotTheme = "danger";
				} else if (isStarItem) {
					dotTheme = "success";
				}

				const popOverItems = [];

				if (userCanAdminVacations) {
					popOverItems.push({
						label: "Bearbeiten",
						onClick: () => setEntryDataToUpdate(workingSchedule as WorkingScheduleWithDate),
						icon: PencilIcon,
					});
				}

				if (userCanAdminVacations && !(workingSchedule as WorkingScheduleWithDate).isDateLocked) {
					popOverItems.push({
						label: "Löschen",
						onClick: () => setEntryDataToDelete(workingSchedule as WorkingScheduleWithDate),
						icon: TrashIcon,
					});
				}

				timelineElements.push(
					<TimelineItem date={new Date(workingSchedule.validFrom)}
								  dotTheme={dotTheme}
								  key={index}
								  showHoverEffect={!isLeftEntry}
								  isStartItem={isStarItem}
					>
						<div className="flex w-full justify-between">
							{title}
							{!isLeftEntry && <ButtonWithPopover
								theme="dark"
								items={popOverItems}
							/>}

						</div>
					</TimelineItem>,
				);
			});
			return timelineElements;
		}
		return [];
	}, [employmentsData, workingSchedulesData, userCanAdminVacations]);


	const isLoading = workingSchedulesIsLoading || employmentsIsLoading;
	return staffMemberCoreData.userId && <>
		<Card title="Arbeitszeitmodelle"
			  onHeaderButtonClick={() => setShowCreateEntrySidebar(true)}
			  headerButtonText="Neuer Eintrag"
		>
			{isLoading && <LoadingSpinnerFull theme="neutral" />}
			{!isLoading && <StaffMemberTimeline>
				{timeLineElements.length > 0 ? timeLineElements : <TimelineEmptyMessage />}
			</StaffMemberTimeline>}
		</Card>

		<CreateWorkingScheduleSidebar
			onClose={() => setShowCreateEntrySidebar(false)}
			isOpen={showCreateEntrySidebar}
			staffMemberId={staffMemberCoreData.id}
			workingSchedulesMinDate={minDateNewEntry}
		/>
		<UpdateWorkingScheduleSidebar onClose={() => setEntryDataToUpdate(null)}
									  isOpen={entryDataToUpdate !== null}
									  workingScheduleData={entryDataToUpdate}
									  workingSchedulesMinDate={minDateNewEntry} />

		<DeleteWorkingScheduleModal
			isOpen={entryDataToDelete !== null}
			onCloseClick={() => setEntryDataToDelete(null)}
			workingScheduleData={entryDataToDelete} />

	</>;
};

export default WorkingSchedulesSection;