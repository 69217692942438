import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getAllProjects,
	getClientsProjects,
	getProject,
	indexProjectDescriptions,
} from "~/modules/project/api/project/projectApiDispatchers.ts";

export const PROJECT_BASE_QUERY_KEY = "project";
export const PROJECT_QUERY_KEY = (projectId: string) => [PROJECT_BASE_QUERY_KEY, projectId];

export const CLIENTS_PROJECTS_QUERY_KEY = (clientId: string) => [PROJECT_BASE_QUERY_KEY, { clientId }];
export const ALL_PROJECTS_QUERY_KEY = [PROJECT_BASE_QUERY_KEY, "all"];

export const ALL_PROJECT_DESCRIPTIONS_QUERY_KEY = () => [PROJECT_BASE_QUERY_KEY, "allDescriptions"];

export const useProject = (projectId: string) => useQuery({
	queryKey: PROJECT_QUERY_KEY(projectId),
	queryFn: () => getProject(projectId).then(getDataFromResponse),
	enabled: !!projectId,
});

export const useClientsProjects = (clientId: string) => useQuery({
	queryKey: CLIENTS_PROJECTS_QUERY_KEY(clientId),
	queryFn: () => getClientsProjects({ clientId }).then(getDataFromResponse),
	enabled: !!clientId,
});

export const useAllProjects = (enabled: boolean = true) => useQuery({
	queryKey: ALL_PROJECTS_QUERY_KEY,
	queryFn: () => getAllProjects().then(getDataFromResponse),
	enabled,
});

export const useAllProjectDescriptions = () => useQuery({
	queryKey: ALL_PROJECT_DESCRIPTIONS_QUERY_KEY(),
	queryFn: () => indexProjectDescriptions().then(getDataFromResponse),
});