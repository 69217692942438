import type { Row, SortingState } from "@tanstack/react-table";
import { getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { isSameMonth } from "date-fns";
import type { FunctionComponent } from "react";
import { useCallback, useMemo, useState } from "react";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";

import BreadcrumbsSection from "~/components/Breadcrumbs";
import Button from "~/components/buttons/Button";
import { MonthPickerInputPlain } from "~/components/formElements/MonthPickerInput/MonthPickerInput.tsx";
import Headline from "~/components/Headline";
import Section from "~/components/sections/Section";
import Table from "~/components/Table";
import { HR_STAFF_MEMBER_DETAILS_PATH_WITH_TAB_ID } from "~/constants/appRoute.ts";
import { EMPTY_VALUE_PLACEHOLDER } from "~/constants/textConstants.ts";
import type { CareerLevelId } from "~/modules/humanResources/api/careerLevel/careerLevelTypes.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import type {
	StaffMonthlyReportEntry,
} from "~/modules/humanResources/api/staffMonthlyReport/staffMonthlyReportTypes.ts";
import HrMainPageTabs from "~/modules/humanResources/components/HrMainPageTabs";
import CompensationSectionLockedMessage
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/CompensationSectionLockedMessage";
import DownloadMonthlyReportExcelButton
	from "~/modules/humanResources/components/StaffMonthlyReportView/components/DownloadMonthlyReportExcelButton";
import ToggleTableVisibilityButton
	from "~/modules/humanResources/components/StaffMonthlyReportView/components/ToggleTableVisibilityButton";
import {
	monthlyReportTableColumnDefs,
} from "~/modules/humanResources/components/StaffMonthlyReportView/monthlyReportTableColumnDefs.tsx";
import type {
	MonthlyReportTableData,
	MonthlyReportTableMeta,
} from "~/modules/humanResources/types/monthlyReportsTableTypes.ts";
import { StaffMemberDetailsTabId } from "~/modules/humanResources/types/staffMemberDetailsTypes.ts";
import { getCareerLevelDisplayNameById } from "~/modules/humanResources/utils/careerLevelUtils.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { useStaffMemberCompensationVisibilityStore } from "~/stores/useStaffMemberCompensationVisibilityStore.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	isLoading: boolean;
	monthlyReportData: StaffMonthlyReportEntry[] | null | undefined;
	selectedMonthString: string;
	staffMembersData: StaffMember[] | undefined;
};

const StaffMonthlyReportView: FunctionComponent<Props> = ({
	monthlyReportData,
	selectedMonthString,
	staffMembersData,
	isLoading,
}) => {
	const selectedMonth = new Date(selectedMonthString);

	const setSearchParams = useSearchParams()[1];
	const navigate = useNavigate();
	const [sorting, setSorting] = useState<SortingState>([{ id: "staffMemberFullName", desc: false }]);

	const {
		isVisible: tableIsVisible,
		setVisibility: setTableVisibility,
	} = useStaffMemberCompensationVisibilityStore((state) => state);

	const handleRowClick = useCallback((row: Row<MonthlyReportTableData>) => {
		navigate(generatePath(HR_STAFF_MEMBER_DETAILS_PATH_WITH_TAB_ID, {
			staffMemberId: row.original.staffMemberId,
			tabId: StaffMemberDetailsTabId.Compensation,
		}));
	}, [navigate]);

	const tableData: MonthlyReportTableData[] = useMemo(() => {
		if (staffMembersData && monthlyReportData) {
			return monthlyReportData.map((reportEntry) => {
				const fullName = staffMembersData.find((staffMember) => staffMember.id === reportEntry.staffMemberId)!.fullName;
				const careerLevelDisplayName = reportEntry.careerLevelId ? getCareerLevelDisplayNameById(reportEntry.careerLevelId as CareerLevelId) : EMPTY_VALUE_PLACEHOLDER;
				let totalCompensationCents = null;
				let annualVariableCompensationCents = 0;
				let yearlySalaryCents = null;
				let monthlySalaryCents = null;

				if (reportEntry.totalCompensationCents && reportEntry.ftePercentage) {
					totalCompensationCents = reportEntry.totalCompensationCents * reportEntry.ftePercentage / 10000;

					if (reportEntry.variableCompensationPercentage) {
						annualVariableCompensationCents = totalCompensationCents * reportEntry.variableCompensationPercentage / 10000;
					}
					yearlySalaryCents = totalCompensationCents - annualVariableCompensationCents;
					monthlySalaryCents = yearlySalaryCents / 12;
				}

				let totalPaymentCents = 0;

				if (monthlySalaryCents) {
					totalPaymentCents = monthlySalaryCents;

					if (reportEntry.bonusCents) {
						totalPaymentCents += reportEntry.bonusCents;
					}
				}
				let employmentEndDate: Date | null = null;

				if(reportEntry.employmentEndDate) {
					const date = new Date(reportEntry.employmentEndDate);
					if(isSameMonth(date, selectedMonth)) {
						employmentEndDate = date;
					}
				}
				return {
					id: reportEntry.id,
					bonusCents: reportEntry.bonusCents,
					bonusCentsWasUpdated: reportEntry.bonusCentsWasUpdated,
					careerLevelDisplayName: careerLevelDisplayName,
					careerLevelWasUpdated: reportEntry.careerLevelIdWasUpdated,
					compensationReportNotes: reportEntry.compensationReportNotes,
					currentPeriodStartDate: reportEntry.currentPeriodStartDate,
					currentPeriodStartedInCurrentMonth: reportEntry.currentPeriodStartedInCurrentMonth,
					currentPeriodStartTotalCompensationCents: reportEntry.currentPeriodStartTotalCompensationCents,
					employmentEndDate,
					ftePercentage: reportEntry.ftePercentage,
					ftePercentageWasUpdated: reportEntry.ftePercentageWasUpdated,
					monthlySalaryCents: monthlySalaryCents,
					staffMemberId: reportEntry.staffMemberId,
					staffMemberFullName: fullName,
					totalCompensationCents: totalCompensationCents,
					totalCompensationFteCents: reportEntry.totalCompensationCents,
					totalCompensationWasUpdated: reportEntry.totalCompensationCentsWasUpdated,
					totalPaymentCents,
					variableCompensationCents: annualVariableCompensationCents,
					variableCompensationPercentage: reportEntry.variableCompensationPercentage,
					variableCompensationPercentageWasUpdated: reportEntry.variableCompensationPercentageWasUpdated,
					yearlySalaryCents: yearlySalaryCents,
				};
			}).sort(byObjectProperty("staffMemberFullName"));
		}
		return [];
	}, [staffMembersData, monthlyReportData]);

	const table = useReactTable({
		data: tableData,
		columns: monthlyReportTableColumnDefs,
		state: {
			sorting,
			columnPinning: {
				left: ["staffMemberFullName"],
			},
		},
		meta: {
			onRowClick: handleRowClick,
			emptyTableDataMessage: "Es wurden keine Gehaltsdaten gefunden.",
		} as MonthlyReportTableMeta,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});

	const handleMonthPickerInputChange = useCallback((value: Date | null) => {
		if (!value) return;
		const year = value.getFullYear();
		const month = value.getMonth() + 1;
		setSearchParams({ year: year.toString(), month: month.toString() });
	}, [setSearchParams]);

	const selectedMonthIsCurrentMonth = selectedMonth.getMonth() === new Date().getMonth() && selectedMonth.getFullYear() === new Date().getFullYear();

	return (
		<div className="grid h-screen grid-rows-[auto_auto_auto_1fr] overflow-hidden">
			<BreadcrumbsSection pages={["HR", "Reports"]}
								className="bg-white" />
			<Section className="bg-white pb-4 pt-2">
				<div className="flex w-full items-center gap-x-2">
					<Headline type="h2">Gehaltsreport</Headline>
					<MonthPickerInputPlain name="selectedMonth"
										   value={selectedMonth}
										   onChange={handleMonthPickerInputChange}
										   clearable={false}
										   maxDate={new Date()} />
					{!selectedMonthIsCurrentMonth && <Button theme="primary"
															 onClick={() => handleMonthPickerInputChange(new Date())}>Zum
						aktuellen Monat</Button>}
					<div className="ml-auto flex items-center justify-between gap-x-2">
						<ToggleTableVisibilityButton tableIsVisible={tableIsVisible}
													 setTableIsVisible={setTableVisibility} />

						<DownloadMonthlyReportExcelButton downloadDisabled={isLoading || !tableIsVisible}
														  data={tableData}
														  month={selectedMonth} />
					</div>
				</div>

			</Section>
			<Section>
				<HrMainPageTabs selectedTabName={"monthly-report"} />
			</Section>

			<div className="mx-auto size-full max-w-7xl overflow-hidden px-2 pb-10 pt-4 sm:px-6 lg:px-8">
				{!tableIsVisible &&
					<CompensationSectionLockedMessage setVisibility={setTableVisibility} />}
				{tableIsVisible && isLoading && <LoadingPage />}
				{tableIsVisible && !isLoading &&
					<Table table={table}/>
				}
			</div>
		</div>
	);
};
export default StaffMonthlyReportView;