import { createHeadlessEditor } from "@lexical/headless";
import type { CreateEditorArgs } from "lexical";
import type { FC } from "react";
import { useMemo } from "react";

import { editorBaseConfig } from "~/components/TextEditor/editorConfig.ts";
import { getHtmlFromEditorState } from "~/components/TextEditor/richTextEditorUtils.ts";
import { useAggregatedDataV2 } from "~/hooks/useAggregatedDataV2.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAllClients } from "~/modules/client/api/client/clientQueries";
import { useAllCompanyAvatarImages } from "~/modules/image/api/avatarImage/avatarImageQueries.ts";
import {
	useAllDeliverableTags,
	useDeliverableTagUsageReport,
} from "~/modules/project/api/deliverableTags/deliverableTagsQueries.ts";
import { useAllProductTags, useProductTagUsageReport } from "~/modules/project/api/productTags/productTagsQueries.ts";
import { useAllProjectDescriptions, useAllProjects } from "~/modules/project/api/project/projectQueries";
import ProjectDescriptionsIndexView from "~/modules/project/components/ProjectDescriptionsIndexView";
import type {
	ProjectDescriptionCardData,
	ProjectTagComboboxOption,
} from "~/modules/project/types/projectDescriptionViewTypes.ts";
import LoadingPage from "~/pages/LoadingPage";
import { PermissionNames } from "~/types/entityNames.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


const ProjectDetailsPage: FC = () => {
		const guard = userViewGuard(PermissionNames.CanManageProjects);
		if (guard) return guard;

		const { data, options } = useAggregatedDataV2({
			allCompanyAvatarImages: useAllCompanyAvatarImages(),
			clients: useAllClients(),
			deliverableTags: useAllDeliverableTags(),
			deliverableTagUsageReport: useDeliverableTagUsageReport(),
			productTags: useAllProductTags(),
			productTagUsageReport: useProductTagUsageReport(),
			projectDescriptions: useAllProjectDescriptions(),
			projects: useAllProjects(),
		});
		const {
			allCompanyAvatarImages,
			clients,
			deliverableTags,
			deliverableTagUsageReport,
			productTags,
			productTagUsageReport,
			projectDescriptions,
			projects,
		} = data;

		const projectData: ProjectDescriptionCardData[] = useMemo(() => {
			const editor = createHeadlessEditor({ ...editorBaseConfig as CreateEditorArgs });
			if (projects && allCompanyAvatarImages && clients && projectDescriptions && deliverableTags && productTags) {
				return projects.filter(project => project.deliverableTagIds.length > 0 || project.productTagIds.length > 0).sort(byObjectProperty("title")).map((project) => {
					const client = clients.find((client) => client.id === project.clientId);
					const description = projectDescriptions.find((description) => description.projectId === project.id);
					let descriptionHtml = "";
					if (description) {
						descriptionHtml = getHtmlFromEditorState({ editor, editorState: description.text || undefined });
					}
					const avatarImage = allCompanyAvatarImages.find(image => image.clientIds.includes(project.clientId));
					return {
						id: project.id,
						descriptionHtml,
						companyAvatarImage: avatarImage || null,
						clientDisplayName: client?.displayName || "",
						deliverableTagIds: project.deliverableTagIds,
						deliverableTags: project.deliverableTagIds.map(tagId => deliverableTags.find(tag => tag.id === tagId)!).sort(byObjectProperty("displayName")),
						isInternal: project.isInternal,
						manDaysPlanned: project.manDaysPlanned,
						matchedDeliverableTagIds: [],
						matchedProductTagIds: [],
						projectNumber: project.projectNumber,
						productTagIds: project.productTagIds,
						productTags: project.productTagIds.map(tagId => productTags.find(tag => tag.id === tagId)!).sort(byObjectProperty("displayName")),
						title: project.title,
						totalMatches: 0,
					};
				});
			}
			return [];
		}, [allCompanyAvatarImages, clients, deliverableTags, productTags, projectDescriptions, projects]);

		const projectTags = useMemo(() => {
			if (deliverableTags && productTags && deliverableTagUsageReport && productTagUsageReport) {
				const deliverableProductTags: ProjectTagComboboxOption[] = deliverableTags.map(tag => {
					const usages = deliverableTagUsageReport.find(usage => usage.deliverableTagId === tag.id)?.projectIds.length || 0;
					return {
						label: tag.displayName,
						type: "deliverable" as ProjectTagComboboxOption["type"],
						value: tag.id,
						usages,
					};
				}).sort(byObjectProperty("label"));
				const productProductTags: ProjectTagComboboxOption[] = productTags.map(tag => {
					const usages = productTagUsageReport.find(usage => usage.productTagId === tag.id)?.projectIds.length || 0;
					return {
						label: tag.displayName,
						type: "product" as ProjectTagComboboxOption["type"],
						value: tag.id,
						usages,
					};
				}).sort(byObjectProperty("label"));

				return [...productProductTags, ...deliverableProductTags];
			}
			return [];
		}, [deliverableTagUsageReport, deliverableTags, productTagUsageReport, productTags]);


		if (options.loadingPercent < 100) return <LoadingPage pcent={options.loadingPercent} />;

		return (
			<ProjectDescriptionsIndexView
				projectData={projectData}
				projectTags={projectTags} />
		);
	}
;

export default ProjectDetailsPage;

