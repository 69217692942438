import type { FunctionComponent } from "react";
import { useState } from "react";

import Card from "~/components/Card";
import LoadingSpinnerFull from "~/components/LoadingSpinners/LoadingSpinnerFull";
import { getHeadlessEditorInstance, getHtmlFromEditorState } from "~/components/TextEditor/richTextEditorUtils.ts";
import {
	useStaffMembersSpecialAgreements,
} from "~/modules/humanResources/api/staffMemberSpecialAgreement/staffMemberSpecialAgreementQueries.ts";
import UpdateSpecialAgreementsSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberDocumentsSection/components/SpecialAgreementsSection/components/UpdateSpecialAgreementsSidebar";

type Props = { staffMemberId: string };

const SpecialAgreementsSection: FunctionComponent<Props> = ({ staffMemberId }) => {
	const [showUpdateSpecialAgreementsSidebar, setShowUpdateSpecialAgreementsSidebar] = useState<boolean>(false);
	const {
		data: specialAgreementsData,
		isLoading: specialAgreementsIsLoading,
	} = useStaffMembersSpecialAgreements(staffMemberId);

	const html = getHtmlFromEditorState({ editor: getHeadlessEditorInstance(), editorState: specialAgreementsData?.text || undefined });

	return <>
		<Card title="Sondervereinbarungen"
			  onHeaderButtonClick={() => setShowUpdateSpecialAgreementsSidebar(true)}
			  headerButtonText="Bearbeiten"
			  padding="none"
			  height="full"
		>
			{specialAgreementsIsLoading ? <LoadingSpinnerFull theme="neutral" /> :
				<div
					className="editor-input w-full rounded bg-white px-4"
					tabIndex={0}
					dangerouslySetInnerHTML={{ __html: html }} />}
		</Card>
		<UpdateSpecialAgreementsSidebar isOpen={showUpdateSpecialAgreementsSidebar}
										onClose={() => setShowUpdateSpecialAgreementsSidebar(false)}
										staffMemberId={staffMemberId}
										specialAgreementsData={specialAgreementsData || null} />
	</>;
};

export default SpecialAgreementsSection;