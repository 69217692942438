import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getDeliverableTagsUsageReport,
	indexDeliverableTags,
} from "~/modules/project/api/deliverableTags/deliverableTagsApiDispatchers.ts";

export const DELIVERABLE_TAG_BASE_QUERY_KEY = "deliverable-tags";

export const ALL_DELIVERABLE_TAGS_QUERY_KEY = [DELIVERABLE_TAG_BASE_QUERY_KEY, "all"];

export const useAllDeliverableTags = (enabled: boolean = true) => useQuery({
    queryKey: ALL_DELIVERABLE_TAGS_QUERY_KEY,
    queryFn: () => indexDeliverableTags().then(getDataFromResponse),
    enabled
});

export const DELIVERABLE_TAG_USAGE_REPORT_QUERY_KEY = [DELIVERABLE_TAG_BASE_QUERY_KEY, "usage-report"];

export const useDeliverableTagUsageReport = (enabled: boolean = true) => useQuery({
    queryKey: DELIVERABLE_TAG_USAGE_REPORT_QUERY_KEY,
    queryFn: () => getDeliverableTagsUsageReport().then(getDataFromResponse),
    enabled
});